// extracted by mini-css-extract-plugin
export var linkhover = "index-module--linkhover---8LOL";
export var Dentists = "index-module--Dentists--8CSvB";
export var heading = "index-module--heading--Io9OP";
export var previous = "index-module--previous--PnVA-";
export var icon = "index-module--icon--YoKNi";
export var next = "index-module--next--tf9aI";
export var dentist = "index-module--dentist--xucHx";
export var imagecontainer = "index-module--imagecontainer--Ouv2c";
export var image = "index-module--image--LOVCz";
export var noimage = "index-module--noimage--3wZGg";
export var title = "index-module--title--3M8k1";
export var view = "index-module--view--0bV+b";
export var mobileview = "index-module--mobileview--QfyZz";
export var hiddendentist = "index-module--hiddendentist--ThB1X";
export var dentistnav = "index-module--dentistnav--St6l3";
export var bullet = "index-module--bullet--ratSz";
export var activebullet = "index-module--activebullet--8LzAQ";