import React, { useState } from "react";
import { Link } from "gatsby";
import FixedImage from "../fixedImage"
import { GatsbyImage } from "gatsby-plugin-image";

import * as styleSheet from "./index.module.scss"

const Dentists = ({  dentists, icon = null, arrowLightGray, arrowYellow = null }) => {

	const [ currentDentist, setCurrentDentist ] = useState(0);
	const [ dentistsMax ] = useState(dentists.length);

	const previousDentist = () => {
		if( currentDentist === 0 ){
			setCurrentDentist(dentistsMax - 1);
		} else {
			setCurrentDentist(currentDentist - 1)
		}
	}

	const nextDentist = () => {
		if( currentDentist === (dentistsMax - 1) ){
			setCurrentDentist(0);
		} else {
			setCurrentDentist(currentDentist + 1)
		}
	}

  	return (
        <div className={styleSheet.Dentists}>
			{ dentists && dentists.map( (dentist, index) => {

				const {
					id,
					ACFDentist: {
						headshot
					},
					title,
					link
				} = dentist;
				
				return (
                    <Link key={id} to={link} className={currentDentist === index ? styleSheet.dentist : styleSheet.hiddendentist}>
						<div className={styleSheet.imagecontainer}>
							<button className={styleSheet.previous} onClick={(event)=>{ event.preventDefault(); previousDentist(); }} type="button" aria-label="Show previous dentist"><FixedImage image={arrowLightGray} passedClass={styleSheet.icon} /></button>
							<button className={styleSheet.next} onClick={(event)=>{ event.preventDefault(); nextDentist(); }} type="button" aria-label="Show next dentist"><FixedImage image={arrowLightGray} passedClass={styleSheet.icon} /></button>
							{ headshot ? <GatsbyImage
                                image={headshot.localFile.childImageSharp.gatsbyImageData}
                                className={styleSheet.image}
                                alt={title}
								placeholder="none"
                                loading={"eager"} /> : <div className={styleSheet.noimage}></div> }
						</div>
						<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: title}}></span>
						<span className={styleSheet.view}>View profile { icon && <FixedImage image={icon} /> }</span>
						{ arrowYellow && <span className={styleSheet.mobileview}>View profile { icon && <FixedImage image={arrowYellow} /> }</span> }
					</Link>
                );
			})}
			<div className={styleSheet.dentistnav}>
				{ dentists && dentists.map( (dentist, index) => {
					return <button key={`dentistnav-${dentist.id}`} className={currentDentist === index ? styleSheet.activebullet : styleSheet.bullet} type="button" aria-label={`View ${dentist.title}`} onClick={(event)=>{ setCurrentDentist(index); }} ></button>
				})}
			</div>
		</div>
    );
}

export default Dentists;
